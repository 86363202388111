import React, { useState } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Flex from '../atoms/Flex';
import useDocumentScrollThrottled, { ScrollData } from '../../../useDocumentScroll';

const HEADER_HEIGHT = 100;

const Container = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  padding: 0 2rem;
  position: sticky;
  transition: transform 90ms linear;
  top: 0;
  background-color: rgb(25, 32, 40);
  z-index: 9999;
`;

const LineContainer = styled.div`
  flex: 1;
  height: 100%;
  padding: 0 1rem;
  opacity: 0.3;
`;

const Line = styled.div`
  height: 51px;
  border-bottom: 1px solid white;
`;

const NavBar = styled.nav`
  display: flex;
  align-items: center;
`;

const NavOption = styled(Link)`
  padding: 0.5rem 0.5rem;
  margin: 0rem 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: ${props => props.theme.colors.green['600']};
  text-shadow: none;
  text-decoration: none;
  transition: color 0.3s;
  position: relative;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
    color: #fff;
  }
  &:after,
  &:before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
    content: '';
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(-10px);
  }
  &:before {
    top: 0;
    transform: translateY(-10px);
  }
  &:after {
    bottom: 0;
    transform: translateY(10px);
  }
  &:hover::before,
  &:focus::before,
  &:hover::after,
  &:focus:after {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Profile = styled(Image)`
  border-radius: 25px;
  min-width: 50px;
  opacity: 0.5;
`;

const Logo = styled(Image)`
  min-width: 50px;
`;

const Header = () => {
  const [slide, setSlide] = useState('0px');
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  useDocumentScrollThrottled((callbackData: ScrollData) => {
    const { currentScrollTop, previousScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop >= HEADER_HEIGHT;
    if (currentScrollTop > previousScrollTop && isMinimumScrolled) {
      setSlide(`-${HEADER_HEIGHT}px`); //scrolling down
    } else {
      setSlide('0px'); //scrolling up
    }
  });

  return (
    <Container style={{ transform: `translate(0, ${slide})` }}>
      <Flex flex="1" alignItems="center">
        <Link to="/">
          <Logo fixed={data.logo.childImageSharp.fixed} alt="Andreas Raduege" />
        </Link>
        <LineContainer>
          <Line />
        </LineContainer>
      </Flex>
      <NavBar className="cl-effect-21">
        <NavOption to="/about">About</NavOption>
        <NavOption to="/blog">Blog</NavOption>
        <NavOption to="/portfolio">Portfolio</NavOption>
      </NavBar>
      <Flex flex="1" alignItems="center">
        <LineContainer>
          <Line />
        </LineContainer>
        <Flex alignItems="center">
          <NavOption as="a" href="ajraduege@gmail.com">
            Contact
          </NavOption>
          <Profile fixed={data.avatar.childImageSharp.fixed} alt="Andreas Raduege" />
        </Flex>
      </Flex>
    </Container>
  );
};

export default Header;
