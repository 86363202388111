export const colors = {
  white: '#ffffff',
  black: '#000000',
  transparent: 'transparent',
  green: {
    50: '#C8EFD9',
    100: '#A9E5C3',
    200: '#8DDDB0',
    300: '#72D59D',
    400: '#56CD89',
    500: '#3BC476',
    600: '#30A161',
    700: '#267E4C',
    800: '#1B5A36',
    900: '#103721',
  },
};
