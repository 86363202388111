import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import Box, { BoxProps } from './Box';

type FlexProps = BoxProps | FlexboxProps;

const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${flexbox}
`;

export default Flex;
