import React, { ReactNode } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import Header from './molecules/Header';

type Props = {
  children: ReactNode;
};

const Container = styled.div`
  margin: 0 2rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const Content = styled.div`
  margin-top: 2rem;
  grid-column: 4 / 10;
`;

const Layout = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <Header />
    <Container>
      <Content>{children}</Content>
    </Container>
  </ThemeProvider>
);

export default Layout;
