import { colors } from './colors';
import { baseSizes } from './sizes';
import typography from './typography';

export const theme = {
  colors,
  space: baseSizes,
  fonts: {
    body: 'Poppins',
    heading: 'Maragsa',
  },
  ...typography,
};
