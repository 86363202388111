import styled from 'styled-components';
import {
  border,
  color,
  compose,
  layout,
  position,
  space,
  typography,
  SpaceProps,
  LayoutProps,
  TypographyProps,
  ColorProps,
  BorderProps,
  PositionProps,
} from 'styled-system';

export type BoxProps =
  | SpaceProps
  | LayoutProps
  | TypographyProps
  | ColorProps
  | BorderProps
  | PositionProps;

export const styledProps = compose(space, layout, typography, color, border, position);

const Box = styled.div<BoxProps>`
  ${styledProps}
`;

export default Box;
